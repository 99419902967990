<template>
  <div>
    <b-card no-body :style="'color:' + d_menuData.style.color + '; background-color: ' + d_menuData.style.backgroundColor">
      <w-top-menu :p_menuData="d_menuData"></w-top-menu>
      <b-row>
        <b-col cols="12">
          <template v-if="d_totalLogCount > d_logResultPageData['perpage']">
            <b-pagination size="sm" v-model="d_logResultPageData['current']" :total-rows="d_totalLogCount" :per-page="d_logResultPageData['perpage']" aria-controls="log-table"></b-pagination>
          </template>
          <div id="case_table" :style="f_dataSetStyle(300)" tabindex="0">
            <w-table key="data_table" :p_dataset="d_dataset" :p_columnList="logListColumns" :f_calculateRowNoStyle="f_calculateRowNoStyle" :f_calculateStyleDatasetTable="f_calculateStyleDatasetTable" :p_tablePagination="d_logResultPageData" :f_selectCell="f_selectCell" :f_dblClickToCell="f_showWTableCellData"></w-table>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="d_showSearchSettings" v-model="d_showSearchSettings" size="md" title="Arama Ayarları" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              Başlangıç Tarihi
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input type="date" id="d_startingDate" v-model="d_startingDate"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="12">
              Bitiş Tarihi
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-input type="date" id="d_endingDate" v-model="d_endingDate"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="margin-top:25px">
        <b-col cols="6">
          Log Türü Filtreleme:
        </b-col>
        <b-col cols="6">
          <b-form-checkbox-group v-model="d_selectedLogTypes">
            <template v-for="log_type in d_allLogTypes">
              <b-form-checkbox :name="log_type.value" :value="log_type.value">{{ log_type.label }}</b-form-checkbox>
            </template>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showTextFilter" v-model="d_showTextFilter" size="md" title="Arama İfadesi" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="4">
          Arama İfadesi
        </b-col>
        <b-col cols="8">
          <b-input type="text" id="d_searchText" placeholder="Arama İfadesi Giriniz" v-model="d_searchText"></b-input>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showWTableCellData.show" v-model="d_showWTableCellData.show" size="xl" title="Hücre Değeri" centered scrollable hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="12">
          {{ d_showWTableCellData.text }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogService from '@/services/log';
import store from '@/store';
import moment from 'moment';
import {
  default as WTopMenu
} from '@/components/widgets/WTopMenu';
import {
  default as WTable
} from '@/components/widgets/WTable';
// import {
//   default as Modal
// } from '@/components/widgets/Modal';

export default {
  name: 'Logs',
  components: {
    WTable,
    WTopMenu
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      d_showSearchSettings: false,
      d_showTextFilter: false,
      d_menuData: {
        'selected_menu': 'general', // your defined menu value below
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
          'label': 'Genel',
          'icon': require('@/icon/2979152.png'),
          'value': 'general',
          'list': [{
            'icon': '',
            'label': 'Genel',
            'value': 'general',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Ayarlar',
              'icon': require('@/icon/1584256.png'),
              'label': '',
              'value': 'general',
              'click': this.f_showSearchSettings
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Filtreleme',
              'icon': require('@/icon/2447096.png'),
              'label': '',
              'value': 'wdmnamelanguage',
              'click': this.f_showTextFilter
            }]
          }, {
            'icon': '',
            'label': 'Güncelle',
            'value': 'refresh',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Verileri Güncelle', // save
              'icon': require('@/icon/615577.png'),
              'label': '',
              'value': 'f_getLogs',
              'click': this.f_getLogs
            }]
          }]
        }, {
          'label': 'Yardım',
          'icon': require('@/icon/2249810.png'),
          'value': 'help',
          'list': [{
            'icon': require('@/icon/2249810.png'),
            'label': 'Genel',
            'value': 'general',
            'list': []
          }]
        }]
      },
      d_logResultPageData: { 'current': 1, 'perpage': 100 },
      logListColumns: [{
        value: "date",
        label: "Tarih"
      }, {
        value: "userFirstAndLastName",
        label: "Kullanıcı Adı"
      }, {
        value: "hospitalGroup",
        label: "Hastane Grubu"
      }, {
        value: "hospitalCode",
        label: "Hastane Kodu"
      }, {
        value: "codeType",
        label: "Kod"
      }, {
        value: "info",
        label: "Bilgi"
      }, {
        value: "typeError",
        label: "Type Error"
      }, {
        value: "valueError",
        label: "Value Error"
      }],
      d_searchText: '',
      user: {},
      d_startingDate: '',
      d_endingDate: '',
      d_selectedLogTypes: ['error', 'information'],
      d_showMode: true,
      d_allLogTypes: [{ 'value': 'error', 'label': 'Error' }, { 'value': 'information', 'label': 'Information' }],
      d_totalLogCount: 0,
      d_dataset: [],
      d_selectedCells: [],
      d_showWTableCellData: { 'text': '', 'show': false }
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_startingDate = moment(new Date()).format('YYYY-MM-DD');
    this.d_endingDate = moment(new Date()).format('YYYY-MM-DD');
  },
  mounted: function () {
    this.f_getLogs();
  },
  destroyed () {},
  methods: {
    f_showWTableCellData: function (row, col_value) {
      this.d_showWTableCellData.text = row[col_value];
      this.d_showWTableCellData.show = true;
    },
    f_selectCell: function (row_ind, col_ind, type) {
      if (type === 'cell') {
        let select = row_ind.toString() + '-' + col_ind.toString();
        if (this.d_selectedCells.indexOf(select) === -1) {
          this.d_selectedCells.push(select);
        } else {
          this.d_selectedCells.splice(this.d_selectedCells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        for (let c in this.logListColumns) {
          let y = row_ind.toString() + '-' + c.toString();
          this.d_selectedCells.push(y);
        }
      } else if (type === 'col') {
        for (let r in this.d_dataset) {
          let y = r.toString() + '-' + col_ind.toString();
          this.d_selectedCells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_dataSetStyle: function (x) {
      let height = window.innerHeight - x;
      let overflow_x = 'auto';
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
    },
    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      if (this.d_selectedCells.indexOf(row_ind.toString() + '-' + col_ind.toString()) !== -1) {
        style += 'background-color: #c6fcd8;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: white; font-size: 10px; color: black';
      return style;
    },
    f_showSearchSettings: function () {
      this.d_showSearchSettings = true;
    },
    f_showTextFilter: function () {
      this.d_showTextFilter = true;
    },
    f_getLogs: function (keep_page = false) {
      this.d_selectedCells = [];
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Loglar güncelleniyor.' } });
      if (!keep_page) {
        this.d_logResultPageData['current'] = 1;
      }
      this.d_totalLogCount = 0;
      if (this.d_selectedLogTypes.length === 0) {
        for (let i in this.d_allLogTypes) {
          this.d_selectedLogTypes.push(this.d_allLogTypes[i].value);
        }
      }
      LogService.getLogs({ 'log_type': this.d_selectedLogTypes, 'starting_date': this.d_startingDate, 'ending_date': this.d_endingDate, 'pagination': this.d_logResultPageData, 'search_text': this.d_searchText })
        .then(resp => {
          this.d_showMode = false;
          if (resp.data.status === 'success') {
            this.d_dataset = resp.data.result.log_list;
            this.d_totalLogCount = resp.data.result.log_count;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error' + resp.data.message);
          }
        });
    }
  },
  watch: {
    'd_searchText': function () {
      let text = this.d_searchText;
      setTimeout(function () {
        if (text === this.d_searchText) {
          this.f_getLogs();
        }
      }.bind(this), 2000);
    },
    'd_logResultPageData.current': function () {
      this.f_getLogs(true);
    }
  }
}

</script>

